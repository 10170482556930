import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "30",
  height: "20",
  fill: "none",
  viewBox: "0 0 30 20"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#000",
      d: "M24.36 4.134s-2.413 4.218-5.75 3.416c.77-3.203-.057-3.402-.057-3.402S17.006 5.5 16.594 5.401l-1.882-.452c-.411-.099-1.175-2.006-1.175-2.006s-.828-.2-1.598 3.004C8.603 5.145 8.37.291 8.37.291 1.338.83.109 6.556.109 6.556c5.531.898 5.551 5.01 5.551 5.01 4.75.688 6.089 4.939 6.52 8.063 1.803-2.587 4.927-5.765 9.47-4.22 0 0 1.887-3.654 7.222-1.94 0 0 1.507-5.66-4.511-9.335Z"
    }, null, -1)
  ])))
}
export default { render: render }